import React from 'react'
import { ReactComponent as NotFoundIcon } from '@/assets/icons/utils/notFound.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useNavigate } from 'react-router'

function NotFound() {
  const navigate = useNavigate()

  const refreshPage = () => {
    window.location.reload()
  }
  const gotoHomePg = () => {
    navigate(process.env.REACT_APP_SIFUSE_FRONTEND_URL || '/')
  }
  return (
    <main className='w-full h-full flex items-center justify-center bg-white flex-1'>
      <div className='flex flex-col gap-12 items-center justify-center'>
        <NotFoundIcon />
        <div className='flex flex-col gap-6 items-center justify-center w-[80vw] md:w-[650px]'>
          <hgroup>
            <Typography heading='xl' className='text-center'>
              {`Oops! We couldn't find that page.`}
            </Typography>
            <Typography paragraph='md' className='text-center !text-[#808080]'>
              {` It seems like you've taken a wrong turn. We couldn't find the page
              you were looking for. This might be due to a typing error, a
              temporary issue, or the page may have been moved or deleted.`}
            </Typography>
          </hgroup>

          <span className='flex flex-col gap-2 w-full'>
            <Button
              className='!w-full !h-[54px]'
              color='white'
              onClick={refreshPage}
            >
              Try again
            </Button>
            <Button
              className='!w-full !h-[54px]'
              color='white'
              onClick={gotoHomePg}
            >
              Visit our homepage
            </Button>
          </span>
        </div>
      </div>
    </main>
  )
}

export default NotFound
