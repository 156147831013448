import { object, string } from 'yup'

const format = /^[-@*_\.\w]*$/
const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/

export const forgotPasswordSchema = object().shape({
  email: string().email().required(),
})
export const resetPasswordSchema = object().shape({
  password: string()
    .required('Password is required')
    .matches(
      passwordFormat,
      'Password must have 8 or more characters, at least one uppercase letter, lowercase letter and one number.'
    )
    .test(
      'special-chars',
      'Password cannot contain special characters other than _ @ . -',
      function (value) {
        return format.test(value as unknown as string)
      }
    )
    .strict(true),
  confirm_password: string()
    .min(5)
    .required('Enter Password that matches')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export const changePasswordSchema = object().shape({
  new_password: string()
    .required('Password is required')
    .matches(
      passwordFormat,
      'Password must have 8 or more characters, at least one uppercase letter, and one number.'
    )
    .test(
      'special-chars',
      'Password cannot contain special characters other than _ @ . -',
      function (value) {
        return format.test(value as unknown as string)
      }
    )
    .strict(true),
  old_password: string().required('Old password is required').min(5),
})
