import { addDataToQueryString } from '@/helpers/strings'
import { PaginatedData, PaginationReq, ServerRes } from './api.types'
import api from './api'
import { EVENTS, TOP_EVENTS } from './endpoints'
import { InvestorGroupType } from './investorApis'

export type Event = {
  id: number
  status: Status
  name: string
  description: string
  banner: string
  start: string
  end: string
  location: string
  link: string
  created: string
  updated: string
  attending: boolean
}
export type Status = 'ongoing' | 'upcoming' | 'concluded'
export type EventDetail = Event & {
  organizer: Organiser
  attendees: Attendee[]
  num_attendees: number
  liked: boolean
  investor_group?: InvestorGroupType
}
export interface Attendee extends Organiser {
  email: string
  startup?: string
  about?: string
}

interface Organiser {
  id: number
  first_name: string
  last_name: string
  avatar: string
  user_type: string
}

export const GetTopEvents = async () => {
  return (await api.get<ServerRes<Event[]>>(TOP_EVENTS)).data.data
}
export const GetEvents = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Event>>>(
      EVENTS + (qs ? `?${qs}` : '')
    )
  ).data?.data
}

export const GetEventDetail = async (data: { event: number }) => {
  return (await api.get<ServerRes<EventDetail>>(`${EVENTS}${data.event}/`)).data
    ?.data
}

export const CreateEvent = async (data: Record<string, any>) => {
  return await api.post<ServerRes<Event>>(EVENTS, data)
}

export const UpdateEvent = async (data: Record<string, any>) => {
  const event = data?.id

  return await api.patch<ServerRes<Event>>(`${EVENTS}${event}/`, data)
}

export const DeleteEvent = async (data: { event: number }) => {
  return await api.delete<any>(`${EVENTS}${data.event}/`)
}

export const RegisterOrCancelEvent = async (data: { event: number }) => {
  return await api.post<ServerRes<EventDetail>>(
    `${EVENTS}${data.event}/attend/`,
    data
  )
}
export const GetEventAttendees = async (data: PaginationReq) => {
  return (
    await api.get<ServerRes<PaginatedData<Attendee>>>(
      `${EVENTS}${data.event}/attendees/`
    )
  ).data.data
}
