import React from 'react'
import { ReactComponent as SomethingWentWrongIcon } from '@/assets/icons/utils/somethingWentWrong.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useNavigate } from 'react-router'

function SomethingWentWrong() {
  const navigate = useNavigate()

  const refreshPage = () => {
    window.location.reload()
  }
  const gotoHomePg = () => {
    navigate(process.env.REACT_APP_SIFUSE_FRONTEND_URL || '/')
  }
  return (
    <main className='w-full h-full flex items-center justify-center bg-white min-h-[90vh]'>
      <div className='flex flex-col gap-12 items-center justify-center'>
        <SomethingWentWrongIcon />
        <div className='flex flex-col gap-6 items-center justify-center w-[80vw] md:w-[650px]'>
          <hgroup>
            <Typography heading='xl' className='text-center'>
              {`Something went wrong.`}
            </Typography>
            <Typography paragraph='md' className='text-center !text-[#808080]'>
              {`We apologize for the inconvenience. There seems to be a temporary issue on our end preventing the page from loading. Our team is working to resolve this as quickly as possible.`}
            </Typography>
          </hgroup>

          <span className='flex flex-col gap-2 w-full'>
            <Button
              className='!w-full !h-[54px]'
              color='white'
              onClick={refreshPage}
            >
              Refresh page
            </Button>
            <Button
              className='!w-full !h-[54px]'
              color='white'
              onClick={gotoHomePg}
            >
              Visit our homepage
            </Button>

            <Typography button='sm' className='text-center'>
              If the problem persists, please let us know by contacting our{' '}
              <a
                href={process.env.REACT_APP_SIFUSE_FRONTEND_SUPPORT_TEAM_URL}
                target='_blanck'
                className='text-[14px] font-medium text-primary'
              >
                support team.
              </a>
            </Typography>
          </span>
        </div>
      </div>
    </main>
  )
}

export default SomethingWentWrong
