import { object, string, mixed, date, ref } from 'yup'

export const eventSchema = (isUpdate: boolean) =>
  object().shape({
    name: string().required(),
    description: string().required().min(5),
    location: string().required(),
    start: date().typeError('Start Date must be a valid date').required(),
    end: date()
      .min(ref('start'), "End date can't be before Start date")
      .required('End Date/Time is required'),

    link: string().url('Must be a valid url').notRequired(),
    banner: isUpdate
      ? mixed()
          .notRequired()
          .test('fileFormat', 'Upload a banner to proceed', (value) => {
            if (typeof value !== 'string') {
              const supportedFormats = ['png', 'jpeg', 'jpg']
              return (
                supportedFormats.includes(value?.name?.split('.')?.pop()) ||
                supportedFormats.includes(value?.type?.split('/')?.pop())
              )
            }
            return true
          })
          .test('fileSize', 'File size must be less than 20MB', (value) => {
            if (typeof value !== 'string') {
              return value?.size <= 20 * 1024 * 1024
            }
            return true
          })
      : mixed()
          .required('Upload a banner to proceed')
          .test('fileFormat', 'Upload a banner to proceed', (value) => {
            if (value) {
              const supportedFormats = ['png', 'jpeg', 'jpg']
              return (
                supportedFormats.includes(value?.name?.split('.')?.pop()) ||
                supportedFormats.includes(value?.type?.split('/')?.pop())
              )
            }
            return true
          })
          .test('fileSize', 'File size must be less than 20MB', (value) => {
            if (value) {
              return value?.size <= 20 * 1024 * 1024
            }
            return true
          }),
  })
