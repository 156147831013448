import { object, string } from 'yup'

export const signinSchema = object().shape({
  email: string().email().required(),
  password: string()
    .required('Password is required')
    // .matches(
    //   passwordFormat,
    //   'Password must have 8 or more characters, at least one uppercase letter, and one number.'
    // )
    // .test(
    //   'special-chars',
    //   'Password cannot contain special characters other than _ @ . -',
    //   function (value) {
    //     return format.test(value as unknown as string)
    //   }
    // )
    .strict(true),
})
