import React from 'react'
import { ReactComponent as AccessDeniedIcon } from '@/assets/icons/utils/accessDenied.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useNavigate } from 'react-router'

function AccessDenied() {
  const navigate = useNavigate()
  return (
    <main className='w-full h-full flex items-center justify-center bg-white flex-1'>
      <div className='flex flex-col gap-12 items-center justify-center'>
        <AccessDeniedIcon />
        <div className='flex flex-col gap-6 items-center justify-center w-[80vw] md:w-[650px]'>
          <hgroup>
            <Typography heading='xl' className='text-center'>
              {`Access Denied`}
            </Typography>
            <Typography paragraph='md' className='text-center !text-[#808080]'>
              {`You do not have permission to access this page. This could be due to incorrect login credentials or insufficient permissions.`}
            </Typography>
          </hgroup>

          <Button
            className='!w-full !h-[54px]'
            color='white'
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </div>
      </div>
    </main>
  )
}

export default AccessDenied
